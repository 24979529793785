




















import Vue, { PropType } from 'vue'
import { IMachines } from '@/types/machines'
import AuthService from '@/services/authService'
import { IUserData } from '@/types/user'
import ApiClient from '@/services/apiClient'
import axios from 'axios'
import { snackbarActions } from '@/store/modules/Snackbar/actions'
import DanaApi from '@/services/danaApi'

export default Vue.extend({
	name: 'ExtendLicenceDialog',
	props: {
		value: {
			type: Boolean,
			default: () => false,
		},
		machineDetail: {
			type: Object as PropType<IMachines>,
			required: true,
		},
	},
	computed: {
		isLicence(): boolean {
			return !!this.machineDetail.system_licence?.datetime_to
		},
		userData(): IUserData {
			return AuthService.getUserData()
		},
	},
	methods: {
		async submit(userData: IUserData, machineDetail: IMachines) {
			try {
				await new DanaApi().post('/management/request_licence', {
					email: userData.email,
					sn: machineDetail.sn,
					mac: machineDetail.mac,
					room: machineDetail.location.name,
					expiration_date: machineDetail.system_licence?.datetime_to,
				})

				await this.$store.dispatch('snackbar/' + snackbarActions.ADD_SNACKBAR, {
					active: true,
					message: 'Request has been successfully send',
					color: 'success',
				})
			} catch (error) {
				if (error) {
					if (axios.isAxiosError(error)) {
						if (error.response) {
							if (error.response.status === 422) {
								await this.$store.dispatch('snackbar/' + snackbarActions.ADD_SNACKBAR, {
									active: true,
									message: `Error: ${[error.response.data.detail[0].loc[1]]} ${[error.response.data.detail[0].msg]}`,
									color: 'error',
								})
							} else if (error.response.status === 400) {
								await this.$store.dispatch('snackbar/' + snackbarActions.ADD_SNACKBAR, {
									active: true,
									message: error.response.data.detail,
									color: 'error',
								})
							} else {
								console.error(error)
							}
						} else {
							console.error(error)
						}
					} else {
						console.error(error)
					}
				}
			}
		},
	},
})
