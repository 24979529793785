



























import Vue from 'vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import { IDataTable, IDataTableHeader, IFilter } from '@/types/table'
import { IMachines, IMachinesTableQuery } from '@/types/machines'
import { IChoice } from '@/types/select'
import ExtendLicenceDialog from '@/components/Dialogs/ExtendLicenceDialog.vue'
import moment from 'moment-timezone'
import ApiClient from '@/services/apiClient'
import { DanaQuery, DanaResponse, IMachine } from '@/types/dana'
import DanaApi from '@/services/danaApi'

export default Vue.extend({
	name: 'Machines',
	components: {
		ExtendLicenceDialog,
		DataTable,
	},
	data() {
		return {
			tableProps: {
				itemsKey: '',
				sort: false,
				sortBy: [''],
				actions: [
					{
						name: 'actionDetail',
						icon: {
							icon: 'fa-info-circle',
							size: '16',
						},
						tooltip: 'detail',
						color: 'blue',
					},
					{
						name: 'actionExtendLicence',
						icon: {
							icon: 'fa-award',
							size: '16',
						},
						tooltip: 'Extend Licence',
						color: 'blue',
					},
				],
			} as IDataTable,
			machines: [] as Array<IMachine>,
			machinesCount: 0,
			machinesLoading: false,
			locations: [] as Array<IChoice>,
			dialog: false,
			licenceDialog: {} as IMachine,
		}
	},
	computed: {
		headers() {
			const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

			//ten minutes offset
			const lastOnlineSuccess = moment(new Date(new Date(date).setMinutes(date.getMinutes() - 10)).toISOString())
				.format()
				.substring(0, 19)

			//one hour offset
			const lastOnlineWarning = moment(new Date(new Date(date).setHours(date.getHours() - 1)).toISOString())
				.format()
				.substring(0, 19)

			//one month offset
			const licenseSuccess = moment(new Date(new Date(date).setMonth(date.getMonth() + 1)).toISOString())
				.format()
				.substring(0, 19)

			//less than month to today
			const licenseWarning = moment(new Date(date)).format().substring(0, 19)

			return [
				{
					text: 'SN',
					value: 'sn',
				},
				{
					text: 'Mac',
					value: 'mac',
				},
				{
					text: 'Game System',
					value: 'multigame.name',
				},
				{
					text: 'Room',
					value: 'location.name',
				},
				{
					text: 'License',
					value: 'system_licence.datetime_to',
					type: 'dateTime',
					colorSwatch: {
						success: licenseSuccess,
						warning: licenseWarning,
					},
				},
				{
					text: 'Last Online',
					value: 'last_online',
					type: 'dateTime',
					colorSwatch: {
						success: lastOnlineSuccess,
						warning: lastOnlineWarning,
						featureLock: true,
					},
				},
			] as Array<IDataTableHeader>
		},
		filters(): Array<IFilter> {
			return [
				{
					source: 'locations',
					type: 'SelectInput',
					label: 'Room Filter',
					attributes: {
						multiple: true,
					},
					choices: this.locations,
				},
			]
		},
	},
	methods: {
		async getMachines(options: IMachinesTableQuery) {
			this.machinesLoading = true

			const offset = (): number | undefined => {
				if (options.pagination.page && options.pagination.itemsPerPage) {
					return (options.pagination.page - 1) * options.pagination.itemsPerPage
				} else {
					return undefined
				}
			}

			try {
				const response = await new DanaApi().get<DanaResponse.IMachines, DanaQuery.IMachines>('/info/machines', {
					limit: options.pagination?.itemsPerPage,
					offset: offset(),
					location_id: options.filters?.locations,
				})

				let rawMachines = response
				let machines: IMachines[] = []

				for (const obj of rawMachines.machines) {
					const machine: Partial<IMachines> = {}

					;(Object.keys(obj) as (keyof IMachine)[]).find((key): void => {
						//TODO fix
						//@ts-ignore
						machine[key] = obj[key]

						if (key === 'licences') {
							machine.system_licence = null
							for (const licence of obj.licences) {
								if (licence.type_name === 'plain_licence' || licence.type_name === 'system_licence') {
									machine.system_licence = licence

									break
								}
							}
						}
					})

					//TODO fix
					//@ts-ignore
					machines.push(machine)
				}

				this.machines = machines
				this.machinesCount = response.count
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}

			this.machinesLoading = false
		},
		async getLocations() {
			try {
				const response = await new DanaApi().get<DanaResponse.ILocations[], unknown>('/info/locations')

				this.locations = response.map(
					(value): IChoice => ({
						value: value.id,
						text: value.name,
					}),
				)
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}
		},
		actionDetail(event: IMachines): void {
			this.$router.push({
				path: `/machines/detail/${event.id}`,
			})
		},
		actionExtendLicence(event: IMachines): void {
			this.licenceDialog = event
			this.dialog = true
		},
		fetchMachines(event: IMachinesTableQuery) {
			this.getLocations()
			this.getMachines(event)
		},
	},
})
